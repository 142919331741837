var $ = jQuery.noConflict();
var md = new MobileDetect(window.navigator.userAgent);

// var rellax = new Rellax('.rellax');

var swipertypes, swiperLocation, map, swiperSteps, swiperInstagram;

function initGMap() {
	map = new google.maps.Map(document.getElementById('map'), {
		center: {lat: 52.23992, lng: 21.028869},
		zoom: 17
	});
	var marker = new google.maps.Marker({
		position: new google.maps.LatLng(52.23992, 21.028869),
		icon: 'assets/images/location/logo-google-map.svg',
		map: map
	});
}

function initLocation() {
	swiperLocation = new Swiper('.location-swiper', {
		slidesPerView: 2,
		spaceBetween: 0,
		loop: true,
		simulateTouch: false,
		autoplay: {
			disableOnInteraction: false,
			delay: 4000
		},
		navigation: {
			nextEl: '.location-swiper .swiper-button-next',
			prevEl: '.location-swiper .swiper-button-prev',
		},
		breakpointsInverse: true,
		breakpoints: {
			1024: {
				loop: false,
				navigation: false,
				slidesPerView: 4,
				autoplay: false,
			},
		}
	});

	$('.map-normal .map-change-bt').click(function () {
		$('.map-google').addClass('active');
	});
	$('.map-google .map-change-bt').click(function () {
		$('.map-google').removeClass('active');
	});
	// if (md.phone()) {
	// 	$('.map-normal .map-change-bt').click();
	// }


	swiperSteps = new Swiper('.steps-swiper', {
		slidesPerView: 1,
		spaceBetween: 0,
		simulateTouch: false,
		navigation: {
			nextEl: '.steps-swiper .swiper-button-next',
			prevEl: '.steps-swiper .swiper-button-prev',
		},
		loop: true,
		autoplay: {
			delay: 4000
		},
		breakpointsInverse: true,
		breakpoints: {
			1024: {
				loop: false,
				navigation: false,
				slidesPerView: 4,
				autoplay: false,
			},
		}
	});
}

function initTypes() {
	swipertypes = new Swiper('.types-swiper', {
		navigation: {
			nextEl: '.types-swiper .swiper-button-next',
			prevEl: '.types-swiper .swiper-button-prev',
		},
		on: {
			slideChangeTransitionStart: function () {
				$('.types-tab').removeClass('active');
			},
			slideChangeTransitionEnd: function () {
				selectTypesSlide();
			},
		}
	});

	selectTypesSlide();
	$('.types-tab').click(function () {
		swipertypes.slideTo($(this).data('slide'));
	});
}

function selectTypesSlide() {
	$('.types-tab[data-slide=' + swipertypes.activeIndex + ']').addClass('active');
}

function initInstagram() {
	$(function () {
		$.getJSON(
			"https://api.instagram.com/v1/users/" + INSTAGRAM_USER_ID + "/media/recent/?access_token=" +
			INSTAGRAM_KEY +
			"&callback=?",
			function (insta) {
				$.each(insta.data, function (photos, src) {
					if (photos === 20) {
						return false;
					}
					$(
						'<div class="swiper-slide"><a href="' +
						src.link +
						'" class="post" target="_blank" style="background-image: url(' +
						src.images.low_resolution.url +
						'">' +
						"</a></div>"
					).appendTo("#instagram-feed");
				});


				swiperInstagram = new Swiper('.instagram-swiper', {
					slidesPerView: 2,
					spaceBetween: 30,
					loop: true,
					autoplay: {
						delay: 3000
					},
					breakpointsInverse: true,
					breakpoints: {
						1024: {
							slidesPerView: 6,
						},
					}
				});
			}
		);
	});

}


function initForm() {
	$('#contact-form').on('submit', function (e) {
		e.preventDefault();
		var tt = $(this);
		// if the validator does not prevent form submit
		// if (!e.isDefaultPrevented()) {
		var url = "send-contact.php";

		// POST values in the background the the script URL
		$.ajax({
			type: "POST",
			url: url,
			data: $(this).serialize(),
			success: function (data) {
				// data = JSON object that contact.php returns

				// we recieve the type of the message: success x danger and apply it to the
				var messageAlert = 'alert-' + data.type;
				var messageText = data.message;

				// let's compose Bootstrap alert box HTML
				var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';

				// if (data.type === 'success') {
				// 	dataLayer.push({'event': 'wyslij'});
				// }

				// If we have messageAlert and messageText
				if (messageAlert && messageText) {
					// inject the alert to .messages div in our form
					tt.find('.messages').html(alertBox);
					// empty the form
					tt[0].reset();
				}
			}
		});
		return false;
		// }
	});

	$('.see-details').click(function () {
		$(".detail-opened").fadeIn();
	});

	$('.detail-close').click(function () {
		$(".detail-opened").fadeOut();
	});
}

function initMainMenu() {

	// Smooth scrolling using jQuery easing
	$('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: (target.offset().top - $('header').height())
				}, 1000, "easeInOutExpo");
				return false;
			}
		}
	});

	$(".navbar-toggler").click(function () {
		$(this).toggleClass('is-active');
	});

	$('.js-scroll-trigger').click(function () {
		$('.navbar-collapse').collapse('hide');
	});

	$('body').scrollspy({
		target: '#main-menu',
		offset: $('header').height() + 5
	});
}

function initStyles() {

	var swiperV = new Swiper('.style-gallery-swiper', {
		direction: 'vertical',
		spaceBetween: 50,
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		autoplay: {
			disableOnInteraction: false,
			duration: 3000
		}
	});

	swiperSteps = new Swiper('.styles-swiper', {
		slidesPerView: 1,
		spaceBetween: 0,
		simulateTouch: false,
		navigation: {
			nextEl: '.styles-swiper .swiper-button-next',
			prevEl: '.styles-swiper .swiper-button-prev',
		},
		loop: true,
		breakpointsInverse: true,
		breakpoints: {
			1024: {
				loop: false,
				navigation: false,
				slidesPerView: 4,
				autoplay: false,
			},
		}
	});

	$(".slider").slick({
		centerMode: true,
		centerPadding: '60px',
		slidesToShow: 1,
		speed: 0,
		variableWidth: true,
		// responsive: [
		// 	{
		// 		breakpoint: 768,
		// 		settings: {
		// 			arrows: false,
		// 			centerMode: true,
		// 			centerPadding: '40px',
		// 			slidesToShow: 1
		// 		}
		// 	},
		// 	{
		// 		breakpoint: 480,
		// 		settings: {
		// 			arrows: false,
		// 			centerMode: true,
		// 			centerPadding: '40px',
		// 			slidesToShow: 1
		// 		}
		// 	}
		// ]
	});
	$('.gallery-next').click(function () {
		$(this).siblings('.slider').first().slick('slickNext');
	}).mousemove(function (e) {
		var $img = $('.gallery-pointer');
		$img.removeClass('prev');

	});
	$('.gallery-prev').click(function () {
		$(this).siblings('.slider').first().slick('slickPrev');
	}).mousemove(function (e) {
		var $img = $('.gallery-pointer');
		$img.addClass('prev');
	});
	$(document).mousemove(function (e) {
		var $img = $('.gallery-pointer');
		// only show if the align attribute has value center
		$img.offset({
			top: e.pageY - $img.outerHeight() - 2,
			left: e.pageX - ($img.outerWidth() - 18)
		});
	});

	$('.galleries-close').click(function () {
		$('.galleries').fadeOut();
		$('.gallery-pointer').show();
	}).mouseenter(function () {
		$('.gallery-pointer').hide();
	}).mouseleave(function () {
		$('.gallery-pointer').show();
	});
	$('.open-gallery-bt').click(function () {
		$('.gallery-item').hide();
		$('.gallery-' + $(this).data('gallery'))
			.css("display", "flex")
			.hide().show();
		$('.gallery-' + $(this).data('gallery') + ' .slider').slick('slickNext');
		$(".galleries").fadeIn();
	});
}

function initAmenities() {
	if (md.mobile()) {
		swiperSteps = new Swiper('.amenities-swiper', {
			slidesPerView: 1,
			centeredSlides: true,
			spaceBetween: 0,
			simulateTouch: false,
			navigation: {
				nextEl: '.amenities-swiper .swiper-button-next',
				prevEl: '.amenities-swiper .swiper-button-prev',
			},

		});
	}
}

function initAnimations() {

	AOS.init({
		disable: 'mobile',
		duration: 800,
		offset: 300
	});
	if (!md.mobile()) {
		luxy.init();

	}
}

$(document).ready(function () {

	initMainMenu();
	initTypes();
	initStyles();
	initAmenities();
	initLocation();
	initInstagram();
	initForm();
	initAnimations();
});


